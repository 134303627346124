<template>
  <list-template
      :current-page='page'
      :loading="loading"
      :search-config='searchConfig'
      :table-config='tableConfig'
      :table-data='tableData'
      :total='total'
      has-add
      @onAdd='add'
      @onChangePage='changeCurrentPage'
      @onHandle='tableHandle'
      @onReset='search = null'
      @onSearch='onSearch'
  >
  </list-template>
</template>

<script>
import {mapState} from 'vuex'
import ListTemplate from "@/components/pages/List.vue"

export default {
_config:{"route":{"path":"list","meta":{"title":"列表","keepAlive":true}}},
components: {ListTemplate},
data() {
  return {
    loading: false,
    // 表格搜索配置
    searchConfig: [
      {
        prop: 'school_id',
        placeholder: '筛选校区',
        tag: 'select',
        options: [],
        label: 'name',
        value: 'id'
      },
      {
        prop: 'grade_id',
        placeholder: '筛选年级',
        tag: 'select',
        options: [],
        label: 'name',
        value: 'id'
      },
    ],
    // 表格配置
    tableConfig: [
      {
        prop: 'name',
        label: '任务名称'
      },
      {
        prop: 'school_name',
        label: '校区'
      },
      {
        prop: 'range',
        label: '适用范围',
        render: (row) => {
          return row.range.join(',')
        }
      },
      {
        prop: 'start_at',
        label: '任务开始时间',
      },
      {
        prop: 'end_at',
        label: '任务结束时间',
      },
      {
        prop: 'rate',
        label: '最低合格率',
        render: (row) => {
          return row.rate + '%'
        }
      },
      {
        prop: 'creator',
        label: '创建人',
      },
      {
        label: '操作',
        handle: true,
        width: '100',
        buttons: [{ type:"view", text:"查看" }]
      }
    ],
    search: null,
    total: 0,
    // 表格中的数据
    tableData: [],
  }
},
mounted() {
  this.$store.commit('setPage', 1)
  this.getData()
},
activated() {
  this.getData()
},
computed: {
  ...mapState(['page'])
},
methods: {
  // 分页被更改
  changeCurrentPage(e) {
    this.$store.commit('setPage', e)
    this.getData()
  },
  /**
   * 获取数据
   */
  getData() {
    let {search, page} = this
    this.loading = true;
    this.$_axios2.get('api/coach-task/task-list', {params: {...search, page}}).then(res => {
      this.tableData = res.data.data.list
      this.total = res.data.data.page.total
    }).finally(() => this.loading = false);
  },
  // 搜索按钮被点击
  onSearch(val) {
    this.$store.commit('setPage', 1)
    this.search = val
    this.getData()
  },
  // 表格后面操作被点击
  tableHandle(row) {
    this.$router.push('./edit?id=' + row.id)
  },
  add() {
    this.$router.push('./add')
  }
},
created() {
  this.$_axios2.get('api/common/school').then(res => {
    this.searchConfig[0].options = res.data.data.school
    this.searchConfig[1].options = res.data.data.action_grade
  })
}
}
</script>

<style lang='scss' scoped>
.list {
padding: 24rem 0 0 32rem;

.search {
  .el-input,
  .el-select {
    width: 240rem;
    margin-right: 24rem;
  }
}

.pagination {
  margin-top: 50rem;
}


}

.title {
font-size: 20rem;
font-weight: bold;
margin-bottom: 20rem;
color: #333333;
}

.title_item {
text-align: right;
}
</style>
